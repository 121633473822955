// tslint:disable: indent
// Date in safari creates with timezone offsewt, but chrome, ff etc... - NO
(() => {
	navigator['who'] = (function () {
		const ua = navigator.userAgent;
		let tem: any[];
		let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			return 'IE ' + (tem[1] || '');
		}

		if (M[1] === 'Chrome') {
			tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
			if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
		return M.join(' ');
	})();

	const [version] = navigator['who'].split(' ');

	function isSafariBrowser() {
		const ua = navigator.userAgent.toLowerCase();
		return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
	}

	if (isSafariBrowser() && Number(version) < 14 && navigator.platform !== 'Win32') {
		try {
			patchDate();
		} catch (error) {
			console.error(
				'Patch Date failed! Apply this patch for safari < 14 vers. only. If you are in simulation, just skip.',
				error
			);
		}
	}

	function patchDate() {
		const DateReal = (window as any).Date;
		const DateRealAny = DateReal as any;

		function DateNew(...args) {
			if (new.target) {
				const date: Date = args.length === 0 ? new DateReal() : new DateRealAny(...args);
				if (args.length === 1 && typeof args[0] === 'string') {
					const withoutTimeZone = new DateReal(date.getTime() + date.getTimezoneOffset() * 60000);
					return withoutTimeZone;
				}
				return date;
			} else {
				return DateRealAny(...args);
			}
		}
		const datePropNames = Object.getOwnPropertyNames(Date);
		for (const prop of datePropNames) {
			DateNew[prop] = Date[prop];
		}
		(window as any).Date = DateNew;
	}
})();
